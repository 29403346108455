
import api from '@/api'
import { IObject } from '@/types/inerface'
import { AxiosRequestConfig } from 'axios'
import { Toast } from 'vant'
import { defineComponent, reactive, toRefs } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'

export default defineComponent({
  name: 'transPlan',
  setup() {
    const store = useStore()
    const router = useRouter()
    const route = useRoute()
    let state = reactive({
      from: route.query,
      to: {
        name: route.query.toName,
        address: route.query.toAddress,
        staCode: route.query.toStaCode
      },
      startSta: '',
      endSta: '',
      plans: []
    })
    api.bus.transPlan({
      staimlgn: state.from.staCode,
      stasta: state.from.name,
      endimlgn: state.to.staCode,
      endsta: state.to.name
    }).then((res: AxiosRequestConfig["data"]) => {
      if (res?.code === 0) {
        console.log(res.data)
        state.plans = res.data.list
        state.startSta = res.data.startSta
        state.endSta = res.data.endSta
      }
    })

    const handleLineDetail = (line: IObject) => {
      const detail = {
        startSta: state.startSta,
        endSta: state.endSta,
        ...line
      }
      store.commit('currentLineDetail', detail)
      router.push('/detail')
    }

    const goBack = () => {
      router.go(-1)
    }

    Toast.loading({
      duration: 500,
      message: '加载中...',
      forbidClick: true,
    });
    
    return {
      handleLineDetail,
      ...toRefs(state),
      goBack
    }
  },
})
