<template>
  <div class="content">
    <van-nav-bar
      title="路线规划"
      left-arrow
      fixed
      safe-area-inset-top
      @click-left="goBack"
    />
    <p class="from-to">
      {{ from.name }} → {{ to.name }}
    </p>
    <div class="plans">
      <div class="plan-item" v-for="item in plans" :key="item.title" @click="handleLineDetail(item)">
        <p class="title">
          <span class="time">{{item.time}}</span>
          <span class="walk">
            <i class="iconfont icon-walking"></i>
            {{item.walk}}
          </span>
        </p>
        <p class="line">
          {{item.title}}
        </p>
        <p class="info">
          <span class="distance">
            {{item.dist}}
          </span>
          <span class="price">
            {{item.price}}
          </span>
        </p>
      </div>
    </div>
    <div class="tips small">部分数据来源：高德实时公交</div>
  </div>
</template>

<script lang="ts">
import api from '@/api'
import { IObject } from '@/types/inerface'
import { AxiosRequestConfig } from 'axios'
import { Toast } from 'vant'
import { defineComponent, reactive, toRefs } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from 'vuex'

export default defineComponent({
  name: 'transPlan',
  setup() {
    const store = useStore()
    const router = useRouter()
    const route = useRoute()
    let state = reactive({
      from: route.query,
      to: {
        name: route.query.toName,
        address: route.query.toAddress,
        staCode: route.query.toStaCode
      },
      startSta: '',
      endSta: '',
      plans: []
    })
    api.bus.transPlan({
      staimlgn: state.from.staCode,
      stasta: state.from.name,
      endimlgn: state.to.staCode,
      endsta: state.to.name
    }).then((res: AxiosRequestConfig["data"]) => {
      if (res?.code === 0) {
        console.log(res.data)
        state.plans = res.data.list
        state.startSta = res.data.startSta
        state.endSta = res.data.endSta
      }
    })

    const handleLineDetail = (line: IObject) => {
      const detail = {
        startSta: state.startSta,
        endSta: state.endSta,
        ...line
      }
      store.commit('currentLineDetail', detail)
      router.push('/detail')
    }

    const goBack = () => {
      router.go(-1)
    }

    Toast.loading({
      duration: 500,
      message: '加载中...',
      forbidClick: true,
    });
    
    return {
      handleLineDetail,
      ...toRefs(state),
      goBack
    }
  },
})
</script>


<style lang="less" scoped>
.content {
  background-color: #ddd;
  overflow: auto;
  padding: 92px 0 20px 0;
  height: 100%;
  box-sizing: border-box;
}

.from-to {
  margin: 0;
  font-size: 28px;
  text-align: center;
  padding: 16px 0;
  border-bottom: 1px solid #eee;
  background-color: #FFF;
  color: #333;
}

.plans {
  margin: 16px 0;
  .plan-item {
    background-color: #fff;
    margin-bottom: 16px;
    box-sizing: border-box;
    padding: 16px;
    color: #333;
    p {
      margin: 0;
    }
    .title {
      margin-bottom: 16px;
      .time {
        font-size: 30px;
        margin-right: 16px;
        color: #1989fa;
      }
      .walk {
        font-size: 26px;
      }
    }
    .line {
      font-size: 26px;
      margin-bottom: 16px;
    }
    .info {
      font-size: 24px;
      color: rgb(150, 150, 150);
      .price {
        margin-left: 16px;
        font-weight: bold;
      }
    }
  }
}

.tips {
  padding: 8px;
  background: @base-background-color;
  text-align: center;
  font-size: 24px;
  color: rgb(117, 117, 117);
  &.small {
    margin-top: 32px;
    font-size: 24px;
  }
}
</style>
